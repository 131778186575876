import React, { useState } from "react"
import { Button, Story, AnFadeIn } from "components"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import { graphql } from "gatsby"
import cx from "classnames"
import moment from "moment"
import {
  articleHeader,
  articleHeaderLeft,
  articleHeaderRight,
  articleHeaderRightNavy,
  articleHeaderRightBlue,
  articleHeaderRightRed,
  articlesList,
  readMoreBtn,
  listFooter,
  filtersClass,
  noneFound,
  navyText,
} from "./styles.module.css"
import { navigate } from "gatsby-link"
import { Helmet } from "react-helmet"
import { processArticles, filterArticles } from "utils/articles"

const JournalPage = ({ data }) => {
  const originalArticles = processArticles(data.allPrismicArticle.nodes)
  const [articles, setArticles] = useState(originalArticles)

  const featuredArticle =
    originalArticles.find(f => f.featured) || originalArticles[0]
  const { uid, image_top, top_colour, title, subtitle, article_date } =
    featuredArticle

  const restOfArticles = articles.filter(f => f.uid !== featuredArticle.uid)

  return (
    <AnFadeIn>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Still Curious | Journal`}</title>
      </Helmet>

      <header className={articleHeader}>
        <div
          className={articleHeaderLeft}
          style={{ backgroundImage: `url(${image_top.url})` }}
        ></div>
        <div
          className={cx(articleHeaderRight, {
            [articleHeaderRightNavy]: top_colour === "Navy",
            [articleHeaderRightBlue]: top_colour === "Light Blue",
            [articleHeaderRightRed]: top_colour === "Red",
          })}
        >
          <div className={top_colour === "Light Blue" ? navyText : ""}>
            <h1>{title.text}</h1>
            <h2>{subtitle.text}</h2>
            {article_date && (
              <h5>{moment(article_date).format("Do MMMM YYYY")}</h5>
            )}
          </div>
          <Button
            className={readMoreBtn}
            navy={top_colour !== "Navy"}
            onClick={() => navigate(`/journal/${uid}`)}
            title={"READ MORE"}
          />
        </div>
      </header>
      <section className={filtersClass}>
        <h5>FILTER STORIES BY:</h5>
        <button
          onClick={() => setArticles(filterArticles(originalArticles, "Work"))}
        >
          Our Work
        </button>
        <button
          onClick={() =>
            setArticles(filterArticles(originalArticles, "Opinions"))
          }
        >
          Opinions
        </button>
        <button
          onClick={() => setArticles(filterArticles(originalArticles, "News"))}
        >
          News
        </button>
        {restOfArticles.length + 1 !== originalArticles.length && (
          <button onClick={() => setArticles(originalArticles)}>
            Show All
          </button>
        )}
      </section>
      <section className={articlesList}>
        {restOfArticles.length === 0 ? (
          <h4 className={noneFound}>No articles found</h4>
        ) : (
          restOfArticles.map((a, i) => (
            <Story
              key={`${a.uid}-${a.client_name.text}-${i}`}
              date={a.article_date && moment(article_date).format("DD/M/YY")}
              image={a.image_thumbnail.url}
              title={a.client_name.text}
              text={`${a.subtitle.text.substr(0, 100)} ...`}
              href={`/journal/${a.uid}`}
            />
          ))
        )}
      </section>
      <footer className={listFooter}>
        <Button
          title="Want to work with us?"
          onClick={() => navigate("/contact")}
        />
        <Button
          navy
          title="More SC News"
          onClick={() => navigate("/contact")}
        />
      </footer>
    </AnFadeIn>
  )
}

export const query = graphql`
  query AllArticles {
    allPrismicArticle(filter: { data: { hidden: { eq: false } } }) {
      nodes {
        data {
          hidden
          featured
          article_date
          client_name {
            text
          }
          top_colour
          title {
            text
          }
          subtitle {
            text
          }
          subtitle {
            text
          }
          image_top {
            url
          }
          image_thumbnail {
            url
          }
        }
        uid
        tags
      }
    }
  }
`

export default withPrismicPreview(JournalPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
