export const sortByData = articles => {
  return articles
}

export const processArticles = (articles, filterKey = "") => {
  const a = articles
    .map(el => ({
      uid: el.uid,
      tags: el.tags,
      ...el.data,
    }))
    .sort((a, b) => {
      const aDate = new Date(a.article_date).getTime()
      const bDate = new Date(b.article_date).getTime()
      if (aDate < bDate) return 1
      if (aDate > bDate) return -1
      return 0
    })

  if (filterKey) return filterArticles(a, filterKey)
  return a
}

export const filterArticles = (articles, filterKey) => {
  return articles.filter(f => f.tags.indexOf(filterKey) > -1)
}
